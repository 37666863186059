import axios from "axios";

export const LOGIN_URL = "/api/v2/users/auth";
export const ME_URL = "/api/v2/users/me";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password, force_admin: true });
}

export function getUserByToken() {
  return axios.get(ME_URL);
}
