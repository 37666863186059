import React from 'react';
import { Redirect } from 'react-router-dom';
import { openAlert } from './Alerts';
import { useHistory } from "react-router-dom";

const MESSAGES = {
    "user_email_already_exists": "Ya existe un usuario con este email"
};

export const useErrorHandler = () => {
    const history = useHistory();
    const func = (request, ignore=[422]) => {
        /*
        Ignore params is an array where you put the default error handler:
            - default 422
        */
        return request.catch(res => {
            let total = {};
            const response = res.response || {'status': 500, data: {}};

            switch (response.status) {
                case 404:
                    if (ignore.indexOf(404) == -1) {
                        openAlert(
                            'danger',
                            'Error',
                            'El recurso no existe o fue eliminado'
                        );
                    }
                    total = {
                        ok: false,
                        error_type: 'not_found',
                        data: response.data
                    }
                    break;
                case 400:
                    if (ignore.indexOf(400) == -1) {
                        let msg = 'Los datos ingresados contienen errores';

                        if (response.data.code && MESSAGES[response.data.code]) {
                            msg = MESSAGES[response.data.code];
                        }

                        openAlert(
                            'danger',
                            'Error',
                            msg
                        );
                    }
                    total = {
                        ok: false,
                        error_type: 'field_error',
                        data: response.data
                    };
                    break;    
                case 422:
                    if (ignore.indexOf(422) == -1) {
                        openAlert(
                            'danger',
                            'Error',
                            'Los datos ingresados contienen errores'
                        );
                    }
                    total = {
                        ok: false,
                        error_type: 'field_error',
                        data: response.data
                    };
                    break;
                case 403:
                    if (ignore.indexOf(403) == -1) {
                        openAlert(
                            'danger',
                            'Error',
                            'Inicio de sesión requerido'
                        );
                        history.push('/auth/login');
                    }
                    total = {
                        ok: false,
                        error_type: 'internal_error',
                        data: response.data
                    }
                    break;
                default:
                    if (ignore.indexOf(500) == -1) {
                        openAlert(
                            'danger',
                            'Error',
                            'Hubo un error al procesar la solicitud'
                        );
                    }
                    total = {
                        ok: false,
                        error_type: 'internal_error',
                        data: response.data
                    }
                    break;
            }
            return total;
        });
    }

    const setInputError = ({ response, setFieldError }) => {
        const set = (key, data) => {
            if (Array.isArray(data)) {
                for (var z in data) {
                    set(key, data[z]);
                }
            } else {
                setFieldError(key, data);
            }
        };

        for (var z of Object.keys(response)) {
            set(z, response[z]);
        }
    };

    return {
        errorHandler: func,
        setInputError: setInputError
    };
};
