import React from "react";
import {
  Typography
} from '@material-ui/core';
import { Store } from 'react-notifications-component';

export const openAlert = (type, title, message) => {
  Store.addNotification({
        title: title,
        message: <Typography style={{color: '#fff'}}>{message}</Typography>,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate_animated", "animate_fadeIn"],
        animationOut: ["animate_animated", "animate_fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
    });
};
