import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, useErrorHandler } from "../../_metronic/_helpers";
import { Demo3Dashboard } from "../../_metronic/_partials/dashboards/Demo3Dashboard"
import * as StatsService from "./StatsService";

const getName = (key) => {
  return {
      'ambients': 'Ambientes',
      'reseller_ambients_ha': "Hectareas de ambientes para revender",
      'reseller_reports_ndvi_ha': "Hectareas de reporte NDVI para revender",
      'reports_ndvi_ha': 'Hectareas reportes NDVI'
  }[key];
};

export function DashboardPage() {
  const { errorHandler } = useErrorHandler();
  const [ users, setUsers ] = useState(0);
  const [ companies, setCompanies ] = useState(0);
  const [ assignedResources, setAssignedResources ] = useState([]);
  const [ pendingResources, setPendingResources ] = useState([]);

  useEffect(() => {
    errorHandler(StatsService.getTenantStats()).then(res => {
      setUsers(res.data.count_users);
      setCompanies(res.data.count_companies);
    });
    errorHandler(StatsService.getTenantPaymentStats()).then(res => {
      setAssignedResources(res.data.assigned_resources);
      setPendingResources(res.data.pending_resource_to_assign);
    });
  }, []);

  return (
    <>
      <div className="row">
          <div className="col-xl-6">
              <Tile 
                className="gutter-b" 
                iconColor="none" 
                widgetHeight="150px"
                count={users} 
                text={"Usuarios"}
                icon={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
          </div>
          <div className="col-xl-6">
            <Tile 
                className="gutter-b" 
                iconColor="success" 
                widgetHeight="150px"
                count={companies} 
                text={"Empresas"}
                icon={toAbsoluteUrl("/media/svg/icons/duotune/finance/fin001.svg")} />
          </div>

      </div>
      <ResourcesAssigned assignedResources={assignedResources} />
      <PendingResources pendingResources={pendingResources} />
    </>
  );
}

const Tile = ({ count, text, icon, className, iconColor, widgetHeight }) => {
    return (
      <>
        <div
          className={`card card-custom ${className}`}
          style={{ height: widgetHeight }}
        >
          <div className="card-body">
            <span className={`svg-icon svg-icon-3x svg-icon-${iconColor}`}>
              <SVG
                src={icon}
              />
            </span>
            <div className="text-dark font-weight-bolder font-size-h2 mt-3">
                {count}
            </div>
  
            <a
              href="#"
              className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
            >
              {text}
            </a>
          </div>
        </div>
      </>
    );
  }


const ResourcesAssigned = ({ assignedResources }) => {
  return (
    <div className="card-spacer bg-white card-rounded flex-grow-1">
      <div class="card-header border-0 pt-0 p-0 m-0">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Recursos asignados</span>
        </h3>
      </div>
      <div className="row m-0">
        {assignedResources.filter(e => getName(e.resource_id) != null).map(res => {
            return (
              <>
                  <div className="col col-3 mb-2 px-0">
                    <div className="font-size-sm text-muted font-weight-bold">
                      {getName(res.resource_id)}
                    </div>
                    <div className="font-size-h4 font-weight-bolder">{res.quantity}</div>
                  </div>
              </>
            );
        })}
      </div>
    </div>
  );
};


const PendingResources = ({ pendingResources }) => {
  return (
    <div className="card-spacer bg-white card-rounded flex-grow-1">
      <div class="card-header border-0 pt-0 p-0 m-0">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Recursos restantes</span>
        </h3>
      </div>
      <div className="row m-0">
        {pendingResources.filter(e => getName(e.resource_id) != null).map(res => {
            return (
              <>
                  <div className="col col-3 mb-2 px-0">
                    <div className="font-size-sm text-muted font-weight-bold">
                      {getName(res.resource_id)}
                    </div>
                    <div className="font-size-h4 font-weight-bolder">{res.quantity}</div>
                  </div>
              </>
            );
        })}
      </div>
    </div>
  );
};