import Cookies from 'js-cookie';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      if (Cookies.get('session_token')) {
        config.headers.Authorization = `Bearer ${Cookies.get('session_token')}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  var join = require('url-join');
  var isAbsoluteURLRegex = /^(?:\w+:)\/\//;
  function ValidateIPaddress(ipaddress) {  
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {  
      return (true)  
    }  
    return (false)  
  } 
    
  axios.interceptors.request.use(function(config) {
    if (process.env.REACT_APP_MOCK_AXIOS != '1') {
      if (!isAbsoluteURLRegex.test(config.url)) {
        let baseUrl = null;
        /*
        if (ValidateIPaddress(window.location.hostname)) {
          if (config.url.indexOf('api/v1/fields') != -1) {
            baseUrl = process.env.REACT_APP_FIELD_API;
          } else if (config.url.indexOf('api/v1/machinery') != -1) {
            baseUrl = process.env.REACT_APP_MACHINERY_API;
          } else if (config.url.indexOf('api/v1/users') != -1) {
            baseUrl = process.env.REACT_APP_USERS_API;
          } else if (config.url.indexOf('api/v1/notifications') != -1) {
            baseUrl = process.env.REACT_APP_NOTIFICATION_API;
          }
        } else {
          baseUrl = "https://" + window.location.hostname.replace("work", "app"); 
        }
        
        config.url = join(baseUrl, config.url);*/
      }
    }

    return config;
  });
}
