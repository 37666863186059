import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";

const Users = lazy(() =>
  import("./modules/Users/pages/UsersModule")
);

const Hardware = lazy(() =>
  import("./modules/Hardware/pages/HardwareModule")
);

const Company = lazy(() =>
  import("./modules/Company/pages/CompanyModule")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/users" component={Users} />
        <Route path={["/hardware", "/commands"]} component={Hardware} />
        <Route path="/company" component={Company} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
